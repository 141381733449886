import corporate from "../assets/img/utils/styles/corporate.svg";
import abstract from "../assets/img/utils/styles/abstract.svg";
import green from "../assets/img/utils/styles/green.svg";
import masculine from "../assets/img/utils/styles/masculine.svg";
import femenine from "../assets/img/utils/styles/femenine.svg";
import geometric from "../assets/img/utils/styles/geometric.svg";
import sweet from "../assets/img/utils/styles/sweet.svg";
import retro from "../assets/img/utils/styles/retro.svg";
import photo from "../assets/img/utils/styles/photo.svg";
import minimal from "../assets/img/utils/styles/minimal.svg";
import threeD from "../assets/img/utils/styles/3D.svg";
import flat from "../assets/img/utils/styles/flat.svg";

const STYLE_OPTIONS = {
  corporate,
  abstract,
  green,
  masculine,
  femenine,
  geometric,
  sweet,
  retro,
  photo,
  minimal,
  threeD,
  flat,
};

export default STYLE_OPTIONS;
