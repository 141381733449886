import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  faExternalLinkAlt,
  faStopwatch,
  faChevronRight,
  faChevronLeft,
  faAngleUp,
  faShieldAlt,
  faPaperPlane,
  faArrowLeft,
  faArrowRight,
  faRocket,
  faMeteor,
  faAtom,
  faEnvelope,
  faQuestionCircle,
  faPlusCircle,
  faMinusCircle,
  faIndustry,
  faComments,
  faMobile,
  faUserAstronaut,
  faSpaceShuttle,
  faSatellite,
  faAt,
  faChartBar,
  faCarrot,
  faPalette,
  faCameraRetro

} from '@fortawesome/free-solid-svg-icons'
import {
  faPlayCircle,
  faPauseCircle
} from '@fortawesome/free-regular-svg-icons'

export const fontawesomeIcons = [
  fab,
  faExternalLinkAlt,
  faStopwatch,
  faChevronRight,
  faChevronLeft,
  faAngleUp,
  faShieldAlt,
  faPaperPlane,
  faArrowLeft,
  faArrowRight,
  faRocket,
  faMeteor,
  faAtom,
  faEnvelope,
  faQuestionCircle,
  faPlusCircle,
  faMinusCircle,
  faIndustry,
  faComments,
  faMobile,
  faUserAstronaut,
  faSpaceShuttle,
  faSatellite,
  faAt,
  faChartBar,
  faPlayCircle,
  faPauseCircle,
  faCarrot,
  faPalette,
  faCameraRetro
]
