import health from "../assets/img/utils/industries/health.svg";
import construction from "../assets/img/utils/industries/construction.svg";
import chemistry from "../assets/img/utils/industries/chemistry.svg";
import realstate from "../assets/img/utils/industries/realstate.svg";
import legal from "../assets/img/utils/industries/legal.svg";
import food from "../assets/img/utils/industries/food.svg";
import education from "../assets/img/utils/industries/education.svg";
import automotive from "../assets/img/utils/industries/automotive.svg";
import ngo from "../assets/img/utils/industries/ngo.svg";
import tech from "../assets/img/utils/industries/tech.svg";
import finance from "../assets/img/utils/industries/finance.svg";
import advertising from "../assets/img/utils/industries/advertising.svg";
import professional from "../assets/img/utils/industries/professional.svg";
import agriculture from "../assets/img/utils/industries/agriculture.svg";

const INDUSTRY_OPTIONS = {
  health,
  construction,
  chemistry,
  realstate,
  legal,
  food,
  education,
  automotive,
  ngo,
  tech,
  finance,
  advertising,
  professional,
  agriculture,
};

export default INDUSTRY_OPTIONS;
