import graph from "../assets/img/landing/main/graph.svg";
import brand_guidelines from "../assets/img/landing/main/brand_guidelines.svg";
import product from "../assets/img/landing/main/product.svg";
import process from "../assets/img/landing/main/process.svg";
import project_presentation from "../assets/img/landing/main/project_presentation.svg";
import investment from "../assets/img/landing/main/investment.svg";
import architecture from "../assets/img/landing/main/architecture.svg";
import app_usage from "../assets/img/landing/main/app_usage.svg";
import team_present from "../assets/img/landing/main/team_present.svg";
import service from "../assets/img/landing/main/service.svg";

const USE_CASES = {
  graph,
  brand_guidelines,
  product,
  process,
  project_presentation,
  investment,
  architecture,
  app_usage,
  team_present,
  service,
};

export default USE_CASES;
