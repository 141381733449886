import red from "../assets/img/utils/color_palettes/red.svg";
import black from "../assets/img/utils/color_palettes/black.svg";
import blue from "../assets/img/utils/color_palettes/blue.svg";
import green from "../assets/img/utils/color_palettes/green.svg";
import orange from "../assets/img/utils/color_palettes/orange.svg";
import purple from "../assets/img/utils/color_palettes/purple.svg";
import turquoise from "../assets/img/utils/color_palettes/turquoise.svg";
import violet from "../assets/img/utils/color_palettes/violet.svg";
import yellow from "../assets/img/utils/color_palettes/yellow.svg";

const COLOR_OPTIONS = {
  red,
  black,
  blue,
  green,
  orange,
  purple,
  turquoise,
  violet,
  yellow,
};

export default COLOR_OPTIONS;
